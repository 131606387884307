import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Contact from "./components/Contact-us/Contact-us";
import Home from "./components/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Business from "./components/Business-developement/Business-developement";
import Consulting from "./components/Consulting/Consulting";
import Education from "./components/Education-services/Education-services";
import Notfound from "./components/Notfound/Notfound";
import ComingSoon from "./components/ComingSoon/ComingSoon";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/" exact component={Home}  />
          <Route path="/contact" component={Contact} />
          <Route path="/business-developement" component={Business} />
          <Route path="/consulting" component={Consulting} />
          <Route path="/education-services" component={Education} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route component={Notfound} />
        </Switch>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
