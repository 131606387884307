import React from "react";
import "./Contact-us.css";

function Contact() {
  return (
    <div id="contact-main">
      <div  className="phone-number">
        <div>
          <h3>Phone:</h3>
        </div>
        <div>
          <p>+91 88007 29191</p>   
        </div>
      </div>
      <div className="email-id">
      <div>
          <h3>Email:</h3>
        </div>
        <div>
          <p>mohanzworld@gmail.com</p>
          
        </div>
      </div>
      <div  className="address">
      <div>
          <h3>Address:</h3>
        </div>
        <div>
          <p>Meerut </p>
          <p>Noida</p>
          <p>Mumbai </p>
          <p> Hyderabad  </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
