import React from "react";
import "./Footer.css"
import {Link} from "react-router-dom"

function Footer() {
  return (
    <footer>
    <h2 className="footer-logo"> Mohan Ventures</h2>
        <div id="footer-content">
        <div >
        <a className="footer-atag" href="https://in.linkedin.com/in/akshat-mohan-sharma-58b57270" target="blank"><i className="fab fa-linkedin itag"></i>Linkedin</a> <br/>
        <Link className="footer-atag" to="/coming-soon"><i className="fab fa-facebook itag"></i>Facebook</Link> <br/>
        <Link className="footer-atag" to="/coming-soon"><i className="fab fa-twitter itag"></i>Twitter</Link> <br/>
        <Link className="footer-atag" to="/coming-soon"><i className="fab fa-instagram itag"></i>Instagram</Link> <br/>
        <Link className="footer-atag" to="/coming-soon"><i className="fab fa-youtube itag"></i>Youtube</Link> <br/>
      </div>
      <div>
        <a className="footer-atag" href="/#intro-content">About us</a> <br/>
        <Link className="footer-atag" to="/coming-soon">Careers</Link> <br/>
        <Link className="footer-atag" to="/coming-soon">Blogs</Link> <br/>
        <Link className="footer-atag" to="/coming-soon">Privacy Policy</Link> <br/>
        <Link className="footer-atag" to="/coming-soon">Terms & Condition</Link>
      </div>
        </div>
        <p className="copyright-line">© 2021 Mohan Ventures all rights reserved.</p>
    </footer>
  );
}

export default Footer;
