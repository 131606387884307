import React, { useState } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";

function Header() {
  const [sideMenuFlag, setSideMenuFlag] = useState(true);

  const sideMenuHandler = () => {
    setSideMenuFlag(!sideMenuFlag)
  };

  return (
    <header>
      <nav id="nav-bar">
        <div className="right-section">
          <NavLink to="/">
            <h2 id="logo"> Mohan Ventures </h2>
          </NavLink>
        </div>

        <div id="menu-bar" onClick={sideMenuHandler}>
          <i className="fas fa-bars"></i>
        </div>

        <div id="menu-item" className={sideMenuFlag ? "active-menu" :  "no-active-menu"}>
          <span onClick={sideMenuHandler}>
            <a href="/#card-section" className="nav-links first-menu">
              Services
            </a>
          </span>
          <span onClick={sideMenuHandler}>
            <a href="/#intro-content" className="nav-links">
              About us
            </a>
          </span>
          <span onClick={sideMenuHandler}>
            <NavLink to="/contact" className="nav-links">
              Contact
            </NavLink>
          </span>
        </div>

        <div className="left-section">
          <span>
            <a href="/#card-section" className="nav-links">
              Services
            </a>
          </span>
          <span className="about-us">
            <a href="/#intro-content" className="nav-links">
              About us
            </a>
          </span>
          <span className="contact">
            <NavLink to="/contact" className="nav-links">
              Contact
            </NavLink>
          </span>
          <span className="get-started">
            <Link to="/" className="header-atag">
              Get Starded
            </Link>
          </span>
        </div>
      </nav>
    </header>
  );
}

export default Header;
