import React from 'react'
import "./Notfound.css"

function Notfound() {
    return (
        <div id="not-found">
            404 Not found!
        </div>
    )
}

export default Notfound;
