import React, { useState } from "react";
import "./Form.css";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = () => {
    alert("Your queries submitted successfully");
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  return (
    <form id="form-data" onSubmit={e => e.preventDefault()}>
      <h3 className="form-heading">Contact with us</h3>
      <span className="name-label">Your Name:</span> <br />
      <input
        type="text"
        value={name}
        name="full-name"
        placeholder="Your Name"
        className="name-input"
        onChange={(e) => setName(e.target.value)}
        required
      />
      <br />
      <span className="name-label">Email:</span> <br />
      <input
        type="email"
        value={email}
        name="email"
        placeholder="Email"
        className="name-input"
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br />
      <span className="name-label">Phone</span>
      <br />
      <input
        type="tel"
        value={phone}
        name="phoneNumber"
        placeholder="Phone"
        className="name-input"
        onChange={(e) => setPhone(e.target.value)}
      />
      <br />
      <span className="name-label">Message</span> <br />
      <textarea
        rows="5"
        name="message"
        cols="50"
        value={message}
        placeholder="type your message here"
        className="name-input"
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>

      <button
        type="submit"
        className="btn btn-outline-success btn-lg form-button"
        onClick={submitHandler}
        disabled = {name && email ? false: true}
      >
        Submit
      </button>
    </form>
  );
}

export default Form;
