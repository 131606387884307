import React from "react";
import self from "../../assets/images/self.jpeg";
import "./Intro.css";

function Intro() {
  return (
    <div id="intro-content">
      <div className="glass-wrapper">
      <div>
        <img className="self-img" src={self} alt="bussiness consultant" />
      </div>
      <div className="intro-line">
          <h3 className="intro-name">Akshat Mohan Sharma </h3> <br/>
          <p className="intro-details">Mohan Ventures offers consulting and execution that helps establish your business, scale your products and realize your future dreams. Akshat Mohan Sharma brings 12+ years of Product, Operations, Technology, Transformation and Operations leadership experience across Consumer, B2B2C, B2B spaces, achieving 0 to 200Mn user adoption. </p>
      </div>
      </div>
    </div>
  );
}

export default Intro;
