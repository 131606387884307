import React from "react";
import SectionFirst from "./SectionFirst/SectionFirst"

function Sections() {
    return (
        <SectionFirst />
    )
}


export default Sections;