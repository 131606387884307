import React from 'react';
import "./ComingSoon.css"

function ComingSoon() {
    return (
        <div id="coming-soon">
            <p className="cs-text">Coming Soon!</p>
            <p className="cs-watch-text">Watch this Space...</p>
        </div>
    )
}

export default ComingSoon;
